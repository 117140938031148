<template>
  <form autocomplete="new-password" class="NoEventsOnLoading">
    <input type="hidden" autocomplete="new-password" />
    <div class="Popup_body_cols">
      <div class="Popup_body_col">
        <div class="form-group" :class="{ WRNG: form.name.invalid }">
          <b>Nume</b>
          <input
            type="text"
            name="I1"
            v-model="form.family.value"
            class="vInput"
            autocomplete="new-password"
            autocapitalize="false"
            autocorrect="false"
          />
        </div>
        <div class="form-group" :class="{ WRNG: form.family.invalid }">
          <b>Prenume</b>
          <input
            type="text"
            name="I2"
            v-model="form.name.value"
            class="vInput"
            autocomplete="new-password"
            autocapitalize="false"
            autocorrect="false"
          />
        </div>
        <div class="form-group" :class="{ WRNG: form.cnp.invalid }">
          <b>
            Cod CNP
            <template v-if="form.cnp.value">
              <i
                v-if="statuses.cnpIsValid"
                class="fas fa-check-circle"
                v-tooltip="'Formatul este introdus corect.'"
                style="color: #43b645"
              ></i>
              <i
                v-else
                class="fas fa-times-circle"
                v-tooltip="'Formatul nu este introdus corect.'"
                style="color: #c84d4d"
              ></i>
            </template>
          </b>
          <div
            class="form-status"
            :class="{
              fActive: statuses.cnpIsUnique !== false,
              fPositive: statuses.cnpIsUnique === 1,
              fNegative: statuses.cnpIsUnique === 2,
            }"
          >
            <input
              type="text"
              name="I3"
              v-model="form.cnp.value"
              class="vInput"
              autocomplete="new-password"
              autocapitalize="false"
              autocorrect="false"
            />
            <span>
              <i
                v-if="statuses.cnpIsUnique === true"
                class="fas fa-sync fa-spin"
              ></i>
              <i
                v-if="statuses.cnpIsUnique === 1"
                class="fas fa-check"
                style="color: #43b645"
                v-tooltip="'Acest Cod CNP este unic!'"
              ></i>
              <i
                v-if="statuses.cnpIsUnique === 2"
                class="fas fa-times-circle"
                style="color: #c84d4d"
                v-tooltip="{
                  content: 'Acest Cod CNP deja există!',
                  offset: 2,
                }"
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div class="Popup_body_col">
        <div class="form-group" :class="{ WRNG: form.username.invalid }">
          <b>Username</b>
          <div
            class="form-status"
            :class="{
              fActive: statuses.usernameIsUnique !== false,
              fPositive: statuses.usernameIsUnique === 1,
              fNegative: statuses.usernameIsUnique === 2,
            }"
          >
            <input
              type="text"
              name="I3"
              v-model="form.username.value"
              class="vInput form-disabled"
              autocomplete="new-password"
              autocapitalize="false"
              autocorrect="false"
              disabled
            />
            <span>
              <i
                v-if="statuses.usernameIsUnique === true"
                class="fas fa-sync fa-spin"
              ></i>
              <i
                v-if="statuses.usernameIsUnique === 1"
                class="fas fa-check"
                style="color: #43b645"
                v-tooltip="'Acest username este unic!'"
              ></i>
              <i
                v-if="statuses.usernameIsUnique === 2"
                class="fas fa-times-circle"
                style="color: #c84d4d"
                v-tooltip="{
                  content: 'Acest username deja există!',
                  offset: 2,
                }"
              ></i>
            </span>
          </div>
        </div>
        <div class="form-group" :class="{ WRNG: form.email.invalid }">
          <b
            >E-mail corespondență
            <template v-if="form.email.value">
              <i
                v-if="statuses.emailIsValid"
                class="fas fa-check-circle"
                v-tooltip="'Formatul este introdus corect.'"
                style="color: #43b645"
              ></i>
              <i
                v-else
                class="fas fa-times-circle"
                v-tooltip="'Formatul nu este introdus corect.'"
                style="color: #c84d4d"
              ></i> </template
          ></b>
          <div
            class="form-status"
            :class="{
              fActive: statuses.emailIsUnique !== false,
              fPositive: statuses.emailIsUnique === 1,
              fNegative: statuses.emailIsUnique === 2,
            }"
          >
            <input
              type="text"
              name="I5"
              v-model="form.email.value"
              class="vInput"
              autocomplete="new-password"
              autocapitalize="false"
              autocorrect="false"
            />
            <span>
              <i
                v-if="statuses.emailIsUnique === true"
                class="fas fa-sync fa-spin"
              ></i>
              <i
                v-if="statuses.emailIsUnique === 1"
                class="fas fa-check"
                style="color: #43b645"
                v-tooltip="'Acest email este unic!'"
              ></i>
              <i
                v-if="statuses.emailIsUnique === 2"
                class="fas fa-times-circle"
                style="color: #c84d4d"
                v-tooltip="{
                  content: 'Acest email deja există!',
                  offset: 2,
                }"
              ></i>
            </span>
          </div>
        </div>
        <div
          class="form-group VTY-margin_1"
          :class="{ WRNG: form.phone.invalid }"
        >
          <b
            >Telefon
            <template v-if="form.phone.value">
              <i
                v-if="phoneValid"
                class="fas fa-check-circle"
                v-tooltip="'Formatul este introdus corect.'"
                style="color: #43b645"
              ></i>
              <i
                v-else
                class="fas fa-times-circle"
                v-tooltip="'Formatul nu este introdus corect.'"
                style="color: #c84d4d"
              ></i>
            </template>
          </b>
          <vue-tel-input
            @input="(a, b) => (statuses.phoneStatus = b || null)"
            v-model="form.phone.value"
          />
        </div>
      </div>
    </div>
    <div v-if="showResetBtn" align="left">
      <div class="hr1" style="margin-bottom: 1rem"></div>
      <div @click="$emit('requestPasswordReset')" class="Button1">
        <div class="icon">
          <img
            @load="imgLoaded"
            src="../assets/media/for_table/circle_ADD_new_etap.svg"
            alt=""
          />
        </div>
        <span class="Btn_content"> Cere resetare parolă </span>
      </div>
    </div>
  </form>
</template>

<script>
import { USERS_SEARCH, USER_CREATE_USERNAME } from '@/api.js'

const defaultFormData = () => ({
  name: {
    value: '',
    invalid: false,
  },
  family: {
    value: '',
    invalid: false,
  },
  cnp: {
    value: '',
    invalid: false,
  },
  username: {
    value: '',
    invalid: false,
  },
  email: {
    value: '',
    invalid: false,
  },
  phone: {
    value: '',
    invalid: false,
  },
})
const defaultStatuses = () => ({
  cnpIsValid: false,
  cnpIsUnique: false,
  usernameIsUnique: false,
  emailIsUnique: false,
  emailIsValid: false,
  phoneStatus: false,
})

const modal20KeysMap = {
  name: 'Input1',
  family: 'Input2',
  cnp: 'Input3',
  username: 'Input4',
  email: 'Input5',
  phone: 'Input6',
}
const modal20StatusesKeysMap = {
  cnpIsUnique: 'cnpIsOk',
  usernameIsUnique: 'usernameIsOk',
  emailIsUnique: 'emailIsUnique',
  phoneStatus: 'phoneStatus',
}

const safeApi = window['apiTimeout'](400, false, false, true)

export default {
  props: {
    Modal20: {
      type: [Boolean, Object],
      default: false,
    },
    showResetBtn: {
      type: Boolean,
      default: false,
    },
    checkerCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: defaultFormData(),
      statuses: defaultStatuses(),
      initialModal20: null,
    }
  },
  computed: {
    phoneValid() {
      return this.statuses.phoneStatus?.valid === true
    },
    oldFormData() {
      return this.deepClone(this.form)
    },
  },
  watch: {
    statuses: {
      handler() {
        this.syncChanges()
      },
      deep: true,
    },
    oldFormData: {
      handler(curr, old) {
        if ((curr.name !== old.name || curr.family !== old.family) && curr.name.value != '' && curr.family.value != '') {
          USER_CREATE_USERNAME({ uuid: this.Modal20.uuid, first_name: curr.name.value, last_name: curr.family.value }).then(res => {
            this.form.username.value = res.data
          }).catch(err => {})
      } else {
        this.form.username.value = '';
      }

        const trim = str => String(str).trim()

        const checkerError = (ctx, msg) => {
          if (ctx && Array.isArray(ctx[0])) {
            ctx = ctx[0]

            if (ctx[2]) {
              this.$toastr.e(
                `Verificarea identității pentru ${ctx[2]} a eșuat${
                  msg ? `: ${msg}` : '.'
                }`
              )
            }
          }
        }
        const apiCheckerMap = {
          cnp: [
            ['cnp', 'cnpIsUnique', 'codul cpv', 'cnpIsValid'], // keys map
            val => {
              // validator
              val = val || ''

              const checker = (start, length, fromTo, value, prepareFn) => {
                fromTo = fromTo || []

                const segment = val.substr(start, length)
                const x = +segment

                if (!trim(segment) || isNaN(x)) {
                  return false
                }

                let isValid = false
                for (let e of fromTo) {
                  if (Number.isInteger(e) && e === x) {
                    isValid = true
                    break
                  }

                  if (Array.isArray(e) && x >= e[0] && x <= e[1]) {
                    isValid = true
                    break
                  }
                }
                return isValid && (prepareFn ? prepareFn(value, segment) : true)
              }

              const checkMap = [
                [0, 1, [[1, 8]]],
                [1, 2, [[0, 99]]],
                [3, 2, [[1, 12]]],
                [5, 2, [[1, 31]]],
                [
                  7,
                  2,
                  [
                    [1, 48],
                    [51, 52],
                  ],
                ],
                [9, 3, [[1, 999]]],
                [
                  12,
                  1,
                  [[0, 9]],
                  (value, char) => {
                    if (!value || !char) return false

                    const checkingSegment = value?.slice(0, 12)
                    const key = '279146358279'

                    let prepareSum = 0
                    for (let i = 0; i < checkingSegment.length; i++) {
                      let findInt = +checkingSegment[i]
                      if (isNaN(findInt)) return false

                      prepareSum += findInt * +key[i]
                    }
                    prepareSum = prepareSum % 11
                    if (prepareSum === 10) prepareSum = 1

                    return prepareSum === +char
                  },
                ],
              ]

              if (trim(val).length !== 13) {
                return false
              }

              for (let e of checkMap) {
                e.splice(3, 0, val)
                if (!checker(...e)) {
                  return false
                }
              }

              return true
            },
          ],
          username: [
            ['email', 'usernameIsUnique', 'username'],
            val => trim(val).length > 2,
          ],
          email: [
            ['emailForCommunication', 'emailIsUnique', 'email', 'emailIsValid'],
            val => trim(val).length > 2 && this.validateEmail(val),
          ],
        }
        const personNameChecker = /[^a-zA-ZăîâșțĂÎÂȘȚ]/

        const validateValuesMap = {
          name: val => val?.replace(personNameChecker, ''),
          family: val => val?.replace(personNameChecker, ''),
          cnp: val => val?.replace(/[^\d]/, '').substr(0, 13),
          phone: val => val?.replace(/[^\d ]/, ''),
        }

        for (let e of Object.entries(curr)) {
          const key = e[0]
          const val = e[1].value

          if (typeof validateValuesMap[key] == 'function') {
            this.$nextTick(
              () => (this.form[key].value = validateValuesMap[key](val))
            )
          }

          if (val !== old[key].value) {
            this.form[key].invalid = false

            const x = apiCheckerMap[key]
            if (Array.isArray(x)) {
              const keysMap = x[0]
              const setStatus = val => (this.statuses[keysMap[1]] = val)
              const setValidationStatus = val =>
                keysMap[3] ? (this.statuses[keysMap[3]] = val) : undefined

              if (typeof x[1] == 'function' && x[1](val)) {
                setValidationStatus(true)

                if (val === this.initialModal20[modal20KeysMap[key]]) {
                  setStatus(3)
                  continue
                }
                safeApi(() => {
                  setStatus(true)
                  USERS_SEARCH(keysMap[0], val)
                    .then(res => {
                      if (val !== this.form[key].value) {
                        return
                      }

                      if (!Number.isInteger(res?.data?.recordsQuantity)) {
                        checkerError(x)
                        return
                      }
                      if (res.data.recordsQuantity > 0) {
                        setStatus(2)
                      } else {
                        setStatus(1)
                      }
                    })
                    .catch(err => checkerError(x, err))
                })
              } else if (keysMap) {
                setValidationStatus(false)
                setStatus(false)
              }
            }
          }
        }

        this.syncChanges()
      },
      deep: true,
    },
    checkerCount() {
      this.checkValid()
    },
  },
  methods: {
    checkValid() {
      const trim = str => String(str).trim()
      for (let e of Object.entries(this.form)) {
        if (!trim(e[1].value)) {
          e[1].invalid = true
        }
      }
    },
    syncChanges(readChanges) {
      if (!this.Modal20) {
        return
      }

      if (!this.initialModal20) {
        this.initialModal20 = this.deepClone(this.Modal20)
      }

      for (let e of Object.entries(modal20KeysMap)) {
        if (readChanges) this.form[e[0]].value = this.Modal20[e[1]]
        else this.Modal20[e[1]] = this.form[e[0]].value
      }
      for (let e of Object.entries(modal20StatusesKeysMap)) {
        if (readChanges) this.statuses[e[0]] = this.Modal20[e[1]]
        else this.Modal20[e[1]] = (x => (x === 3 ? 1 : x))(this.statuses[e[0]])
      }
    },
  },
  created() {
    this.syncChanges(true)
  },
}
</script>

<style></style>
